import { Router } from "@angular/router";
import { Component, OnInit } from '@angular/core';
import { GridOptions } from "ag-grid-community";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';
import { AffinityPartnerService } from './affinity-partner.service';
@Component({
  selector: 'app-affinity-partner',
  templateUrl: './affinity-partner.component.html',
  styleUrls: ['./affinity-partner.component.css']
})
export class AffinityPartnerComponent implements OnInit {

  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs = [
    { headerName: 'Company Name',width: 30, field: 'company_name', sortable: true, filter: true },
    { headerName: 'Referral Code',width: 30, field: 'referral_code', sortable: true, filter: true },
    { headerName: 'Partner URL',width: 70, field: 'affinity_partner_url', sortable: true, filter: true },
    {
      headerName: 'Status',
      width: 20,
      field: 'status',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return params.value === 1 ? 'Active' : 'Inactive';
      }
    },
    {
      headerName: null,
      width: 20,
      cellRenderer: function (params) {
        let buttons = '<button><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></button>';
        if (params.data.status == 0) {
          buttons += '<button title="Do you want to activate company?"><i class="mdi mdi-close-circle-outline text-danger" data-action-type="delete" id="' + params.data.id + '"></i></button>';
        } else {
          buttons += '<button title="Do you want to de-activate company?"><i class="mdi mdi-check-circle-outline activeIcon" data-action-type="delete" id="' + params.data.id + '"></i></button>';
        }
        return buttons;

      }
    }
  ];
  rowData: any;

  constructor(private affinityPartnerService: AffinityPartnerService, private router: Router, public dialog: MatDialog,
    private confirmDialogService: ConfirmDialogService) { }

  ngOnInit(): void {
    this.affinityPartnerService.getAllAffinityPartners().subscribe(data => {
      this.rowData = (data as any).result;
      this.rowData.forEach((row) => {
        row.affinity_partner_url = `${row.affinity_partner_url}/${row.referral_code}`;
      });
    });
  }

  onGridRowClicked(e: any) {
    if (e) {
      let id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {
            this.router.navigate(['/affinity', id]);
            break;
          }
        case "delete":
          { 
            this.deleteDetails(e.data);  
            break;          
          }
      }
    }
  }

  updateCompany(data) {
    this.affinityPartnerService.saveAffinityPartners(data).subscribe(
      (response) => {
        this.gridOptions.api.redrawRows()
      },
      (error) => {
        console.log(error);
      }
    );
  }

  deleteDetails(data) {
    let title = '';
            let message = '';
            let imageType = '';
            if (data.status == 1) {
              data.status = 0;
              message = "Are you sure you want to de-activate " + data.name + "?";
              title = "De-activate Company";
              imageType = 'deactivate';
            }
            else {
              data.status = 1;
              message = "Are you sure you want to Activate " + data.name + "?";
              title = "Activate Company";
              imageType = 'activate';
            }
            const options = {
              title: title,
              message: message,
              imageType: imageType,
              yesNoBtns: true
            };
            this.confirmDialogService.open(options);
            this.confirmDialogService.confirmed().subscribe(confirmed => {
              if (confirmed) {
                this.updateCompany(data);
              }
            });
  }


}
