import { AmlineAddress } from "./address.model";
export class AmlineProposer {
    proposer_type: string = '';
    company_name: string = '';  
    micro_enterprise: boolean = null;  
    year: number = null;  
    address: any = new AmlineAddress();
    is_phone: number = 0;
    sms: number = 0;
    is_email: number = 0;
    post: number = 0;
}
