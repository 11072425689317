import { Component, OnInit, Input } from '@angular/core';
import { Amline } from 'src/app/model/quote/amline/amline.model';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-am-overview',
  templateUrl: './am-overview.component.html',
  styleUrls: ['./am-overview.component.css', '../../../quote.component.css']
})
export class AmOverviewComponent implements OnInit {
  quote;
  quoteApplicationData = new Amline();
  CoverTo;
  @Input() set quoteDetails(value: any) {
    if (value) {
      this.quote = value;
      this.quoteApplicationData = value.application;
      let maxDate = new Date(value.application.inception_date).setFullYear(new Date(value.application.inception_date).getFullYear() + 1);
      this.CoverTo = new Date(new Date(maxDate).getTime() - (24*60*60*1000));
    }
  }

  constructor() { }

  UK_HB_INSURANCE_COMPANY_ID = '';
  ngOnInit(): void {
    this.UK_HB_INSURANCE_COMPANY_ID = environment.UK_HB_INSURANCE_COMPANY_ID;
  }

}
