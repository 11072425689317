<div class="card-header bg-success">
    <h3>Manage Affinity
        <small><a routerLink="/affinity-list" class="text-white float-right"><i class="mdi mdi-arrow-left"></i> Back to
                list</a></small>
    </h3>
</div>


<ng-template mat-tab-label>
    Basic Information
</ng-template>
<div class="card-body">
    <form #f="ngForm" (ngSubmit)="f.form.valid && onSubmit(f)">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Company Name <span class="text-danger">*</span></label>
                    <input type="text" name="company_name" [(ngModel)]="companyDetails.company_name" required
                        #company_name="ngModel" [ngClass]="{ 'is-invalid': f.submitted && company_name.invalid }"
                        class="form-control form-control-sm" placeholder="Company Name">
                    <div *ngIf="f.submitted && company_name.invalid" class="invalid-feedback">
                        Company name is required.
                    </div>
                </div>
            </div>
            <div class="col-md-6" *ngIf="companyDetails.referral_code !=''">
                <div class="form-group">
                    <label>Referral Code</label>
                    <input type="text" name="referral_code" [(ngModel)]="companyDetails.referral_code"
                        #referral_code="ngModel" class="form-control form-control-sm" placeholder="Referral Code"
                        readonly>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Partner URL(Do not add the referral code) <span class="text-danger">*</span></label>
                    <input type="text" name="affinity_partner_url" [(ngModel)]="companyDetails.affinity_partner_url"
                        required #affinity_partner_url="ngModel" class="form-control form-control-sm"
                        placeholder="Partner URL" [readonly]="isReadonly">
                    <div *ngIf="f.submitted && affinity_partner_url.invalid" class="invalid-feedback">
                        Partner URL is required.
                    </div>
                    <a *ngIf="isEdit" href="javascript:void(0);" (click)="editPartnerURL()" style="color:white">
                     <u>Edit URL</u>
                    </a>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Status</label>

                    <select name="status" [(ngModel)]="companyDetails.status" class="form-control form-control-sm"
                        required>
                        <option [value]="1">Active</option>
                        <option [value]="0">Inactive</option>
                    </select>
                    <div *ngIf="f.submitted && companyDetails.status === null" class="invalid-feedback">
                        Please select a status.
                    </div>

                </div>
            </div>
        </div>
        <div class="form-group text-right">
            <input type="submit" name="submit" value="Save Basic Details" class="btn btn-default btn-gray">
        </div>

    </form>



</div>




<!-- Modal For Success Message-->
<!-- <jw-modal id="successMsg" class="messageModal">
    <img src="assets/images/success_icon.jpg">
    <p class="mt-4">{{apiMSG}}</p>
    <div *ngIf="isCompanyPopup">  
    <p>Do you want to update address details?</p>
    <p>
        <a class="btn btn-success ml-3" (click)='NextBtnAction()'>Yes</a>
        <a class="btn btn-default text-white ml-3" (click)='closeModal("successMsg")'>No</a>
    </p>
    </div>
</jw-modal> -->