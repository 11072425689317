import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { AffinityService } from './affinity.service';

@Component({
  selector: 'app-affinity',
  templateUrl: './affinity.component.html',
  styleUrls: ['../quote/quote.component.css']
})
export class AffinityComponent implements OnInit {
  start_date: string = '';
  end_date: string = '';
  paid_status: number = null;
  paid_date: string = '';
  type: number = null;

  constructor(private afs: AffinityService) { }

  ngOnInit(): void {
  }


  download() {
      let data: any = '';
      data = {
        start_date: formatDate(this.start_date, 'yyyy-MM-dd', 'en-US'),
        end_date: formatDate(this.end_date, 'yyyy-MM-dd', 'en-US'),
        type: this.type,
        paid_status: (this.paid_status)?this.paid_status:9,
        paid_date: (this.paid_date?formatDate(this.paid_date, 'yyyy-MM-dd', 'en-US'): ''),
      };

      this.afs.download(data)
      .subscribe(
        (response: any) => {  
          var blob=new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            var link=document.createElement('a');
            link.href=window.URL.createObjectURL(blob);
            link.download="peliwica.xlsx";
            link.click();          
        });
    
    }

}
