import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { ModalService } from '../_modal';
import { AffinityPartnerService } from './affinity-partner.service';
import { AffinityPartnerUpsert } from './affinity-partner-upsert';
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { switchMap } from 'rxjs/operators';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';
import { environment } from '../../environments/environment';
import { UserInfoService } from '../services/user-info.service';
import { GridOptions } from "ag-grid-community";


@Component({
  selector: 'app-affinity-partner-upsert',
  templateUrl: './affinity-partner-upsert.component.html',
  styleUrls: ['./affinity-partner-upsert.component.css']
})

export class AffinityPartnerUpsertComponent implements OnInit {
  parentCompanies;
  roles;
  apiMSG;
  isNewCompany = false;
  activateProducts = false;
  newCompanyID;
  companyDetails = new AffinityPartnerUpsert();
  addressCompanyID;
  routeParamID;
  selectedIndex: number = 0;
  isCompanyPopup = false;
  brokerComission = false;
  imagePath: string = environment.imageURL;
  editMode: boolean = false;
  products = [];
  selectedProducts = [];
  maximum_broker_commission_and_ratained_commission: number = 0;
  maximum_broker_commission_and_ratained_commission_and_peliwica_pay_away_commission: number = 0;
  activateConfiguration = false;

  configurationSubTabs = false;
  productList = false;
  configurationList = true;
  TINY_MCE_KEY = environment.TINY_MCE_KEY;
  SelectedProductId :string="";
  companyId :string="";
  productData;
  isButtonClicked = false;
  isSavedProduct=false;
  activeTabIndex: number;
  isReadonly: boolean = false;
  affinity_base_url:string="";
  isEdit:boolean=false;

  constructor(private formBuilder: FormBuilder, private affinityPartnerService: AffinityPartnerService,
    private modalService: ModalService, private router: Router, private route: ActivatedRoute,
    private confirmDialogService: ConfirmDialogService, private userService: UserInfoService) {

  }

  ngOnInit(): void {

    // GET COMPANY BY ID
    if (this.route.snapshot.params.id) {
      this.isNewCompany = true;
      this.activateProducts = true;
      this.editMode = true;
      this.activateConfiguration = true;
      this.affinityPartnerService.getAffinityPartnerByID(this.route.snapshot.params.id)
        .subscribe(
          (response: any) => {
            this.affinity_base_url=response.result.affinity_partner_url;
            this.companyDetails = response.result;
            this.companyDetails.affinity_partner_url = response.result.affinity_partner_url+'/'+response.result.referral_code;
            this.isReadonly= true;
            this.isEdit = true;
          },
          (error) => {
            console.log(error);
          }
        );
    } 
  }

  ngAfterViewInit() {
    this.activeTabIndex = 1;
  }

  /*
  * Get Uploaded Logo
  */
  fileName: string = '';
  fileToUpload: File = null;
  getUploadedFile(files) {
    this.fileToUpload = files.item(0);
    this.fileName = this.fileToUpload.name;
  }

  // Submit form
  reachedBrokerCommAndRetainedCommMaxLimit: boolean = false;
  reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit: boolean = false;
  onSubmit(f: NgForm) {
    let status = '0';
    if (f.value.status) {
      status = '1';
    }

    let data: any = f.value;

    let formData = new FormData();
    formData.append("id", this.companyDetails.id);
    formData.append("company_name", this.companyDetails.company_name);
    formData.append("affinity_partner_url", this.companyDetails.affinity_partner_url);
    formData.append("status", this.companyDetails.status);


    this.affinityPartnerService.saveAffinityPartners(formData)
      .subscribe(
        (response) => {
          this.apiMSG = (response as any).message;
          if ((response as any).success) {
            this.companyDetails.id = (response as any).result.id;
            this.isCompanyPopup = true;
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'success',
              yesNoBtns: true
            };
            this.confirmDialogService.open(options);
            this.confirmDialogService.confirmed().subscribe(confirmed => {
              if (confirmed) {
                this.selectedIndex += 1;
              }
            });
            this.newCompanyID = (response as any).result.id;
            if ((response as any).result.id != '') {
              this.isNewCompany = true;
              this.newCompanyID = (response as any).result.id;
            }

          } else {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'error',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
            this.confirmDialogService.confirmed().subscribe(confirmed => {
              if (confirmed) {
                // this.updateCompany(data);
              }
            });
          }

        },
        (error) => {
          console.log(error);
        }
      );
  }

  editPartnerURL() {
    this.isReadonly=false;
    this.companyDetails.affinity_partner_url =this.affinity_base_url;
    }
  
}
