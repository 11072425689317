import { AmlineProposer } from "./proposer.model";
import { Vehicle } from "./vehicle.model";
import { Drivers } from "./drivers.model";
import { Fleet } from "./fleet.model";
export class Amline {
    id: string = '';
    broker_id: string = '';
    broker_commission: number = null;
    broker_admin_fee: number = null;
    reference: string = '';
    status: string = '';
    declaration: boolean = null;  
    inception_date: string = '';  
    proposer: any = new AmlineProposer();
    fleet_experiences: any = [new Fleet()];
    rating_type: string = '';  
    vehicles: any = [new Vehicle()];
    driver_conviction_declaration: boolean = null;
    driver_claim_declaration: boolean = null;
    drivers: any = [new Drivers()];
    voluntary_excess: number = null;
    is_referred: boolean = null;
    policy_id: string = '';
    effective_date: string = '';
    old_policy_id: string = '';
    discount: number = 0;
    type: number = 0;
    
}
