import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AmlineService } from '../amline.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuoteService } from '../../quote.service';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-am-quote-view',
  templateUrl: './am-quote-view.component.html',
  styleUrls: ['./am-quote-view.component.css']
})
export class AmQuoteViewComponent implements OnInit {
  staticData;
  id = this.route.snapshot.params.quote_id;
  selectedIndex = 0;
  isAdmin = false;

  constructor(private as: AmlineService, private route: ActivatedRoute, private router: Router, private modalService: NgbModal,
    private quoteService: QuoteService, private sessionSt: LocalStorageService) { }

  ngOnInit(): void {
    if (this.sessionSt.retrieve('is_admin')) {
      this.isAdmin = true;
    }
    this.getAccessToken();
    if (this.sessionSt.retrieve('is_broker')) {
      this.selectedIndex = 5;
    }
  }
  authDetails: string = '';
  getAccessToken() {
    let data = {
      'client_id': environment.AMLINE_CLIENT_ID,
      'client_secret': environment.AMLINE_CLIENT_SECRET,
      'grant_type': environment.AMLINE_GRANT_TYPE,
      'scope': environment.AMLINE_SCOPE,
    }
    this.as.getAccessToken(data)
      .subscribe(
        (response: any) => {
          if (response.access_token) {
            this.authDetails = response;
            this.getStaticData(response);
            this.getQuote(response);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // GET STATIC DATA
  getStaticData(response) {
    this.as.getStaticData(response)
      .subscribe(
        (response: any) => {
          if (response.meta) {
            this.staticData = response.meta;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  // GET QUOTE DETAILS
  quoteDetails;
  fullQuote;
  getQuote(authDetails) {
    this.as.getAmlineData(this.route.snapshot.params.quote_id)
      .subscribe(
        (response: any) => {
          this.quoteDetails = response.result.quote_data.quote_versions[0].amlin_data.data.attributes;
          this.quoteDetails.insurance_status_id = response.result.quote_data.insurance_status_id;
          this.quoteDetails.id = response.result.quote_data.quote_versions[0].id;
          this.quoteDetails.company_id = response.result.quote_data.quote_versions[0].company_id;
          this.quoteDetails.company_name = response.result.quote_data.quote_versions[0].company_name;
          this.quoteDetails.is_send_email = response.result.quote_data.quote_versions[0].is_send_email;
          this.quoteDetails.type = response.result.quote_data.quote_versions[0].type;
          this.quoteDetails.broker_id = response.result.quote_data.quote_versions[0].broker_id;
          this.quoteDetails.notes = response.result.quote_data.quote_versions[0].notes;
          this.quoteDetails.peliwica_fee = response.result.quote_data.quote_versions[0].financial_details.fees;
          this.quoteDetails.broker_commission = response.result.quote_data.quote_versions[0].financial_details.broker_commission;
          this.quoteDetails.broker_admin_fee = response.result.quote_data.quote_versions[0].financial_details.broker_admin_fee;
          this.quoteDetails.email = response.result.quote_data.quote_versions[0].policy_holder?.email;
          this.quoteDetails.phone = response.result.quote_data.quote_versions[0].policy_holder?.phone;
          this.quoteDetails.is_email = response.result.quote_data.quote_versions[0].policy_holder?.is_email;
          this.quoteDetails.is_phone = response.result.quote_data.quote_versions[0].policy_holder?.is_phone;
          this.quoteDetails.sms = response.result.quote_data.quote_versions[0].policy_holder?.sms;
          this.quoteDetails.post = response.result.quote_data.quote_versions[0].policy_holder?.post;
          this.fullQuote = response.result.quote_data.quote_versions[0];
          this.getQuoteNotes();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // Issue Quote
  issueQuotePopup(issueQuoteModal) {
    this.modalService.open(issueQuoteModal, { centered: true });
  }
  issueQuote() {
    let data = {
      "amlin_quote_id": this.route.snapshot.params.quote_id
    };


    this.as.issueQuote(data)
      .subscribe(
        (res: any) => {
          if (res.success) {
            this.modalService.dismissAll();
            let id = this.route.snapshot.params.quote_id;
            this.router.navigateByUrl("/amline/policy/" + id);
          };
        });
  }

  /*
  * Get Notes
  */
  hasImportantNotes: boolean = false;
  totalImpNotes: number = 0;
  getQuoteNotes() {
    this.quoteService.getNotes(this.quoteDetails.id)
      .subscribe(
        (response: any) => {
          let impNotes = response.result.filter(x => x.is_important == 1);
          this.totalImpNotes = impNotes.length;
          if (impNotes.length > 0) {
            this.hasImportantNotes = true;
          }
        });
  }

  onhasImportantNotesChange(event) {
    this.hasImportantNotes = event;
    this.totalImpNotes = this.totalImpNotes + 1;
  }

  displayEmailView: boolean = false;
  displayNewEmailView: boolean = false;
  newEmailView() {
    this.displayNewEmailView = true;
  }

  email_id: string = '';
  getEmailView(email_id) {
    this.email_id = email_id;
    this.displayEmailView = true;
  }
  backToEmailsList() {
    this.displayEmailView = false;
    this.displayNewEmailView = false;
  }
}
