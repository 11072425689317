<div class="card-header bg-success">
    <h3>Manage Affinity
        <a routerLink="/affinity" class="btn btn-default float-right btn-sm ml-5"><i class="mdi mdi-plus"></i>
            Affinity</a>
    </h3>
</div>
<div class="card-body p-1">
    <ag-grid-angular
    style="width: 100%; height: 380px;"
    class="ag-theme-bootstrap"
    [gridOptions]="gridOptions"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    animateRows
    pagination
    paginationPageSize = 10
    rowHeight = 27
    headerHeight=35
    (rowClicked)='onGridRowClicked($event)' 
    >
</ag-grid-angular>
</div>
