<div class="card-header bg-success">
    <div class="row">
        <div class="col-md-6">
            <h3>Affinity</h3>
        </div>
    </div>
</div>
<div class="card-body">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group row">
                <div class="col-md-4">Start Date</div>
                <div class="col-md-8">
                    <input type="text" required name="start_date" placeholder="Start Date"
                        class="form-control form-control-sm datepicker" matInput (focus)="myDatepicker.open()"
                        [matDatepicker]="myDatepicker" [(ngModel)]="start_date">
                    <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker></mat-datepicker>
                </div>
            </div>
        
            <div class="form-group row">
                <div class="col-md-4">End Date</div>
                <div class="col-md-8">
                    <input type="text" required name="end_date" placeholder="End Date"
                        class="form-control form-control-sm datepicker" matInput (focus)="endDateDatepicker.open()"
                        [matDatepicker]="endDateDatepicker" [(ngModel)]="end_date">
                    <mat-datepicker-toggle [for]="endDateDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDateDatepicker></mat-datepicker>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">Paid Status</div>
                <div class="col-md-8">
                    <mat-form-field class="w-100">
                        <mat-label>Paid Status</mat-label>
                        <mat-select [(ngModel)]="paid_status" class="form-control form-control-sm"
                            name="paid_status">
                            <mat-option [value]="2">Not Paid</mat-option>
                            <mat-option [value]="1">Paid</mat-option>
                            <mat-option [value]="9">Both</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row mt-3">
        <div class="col-md-6">
            <label>Transction/Inception Date</label>
            <div class="form-group">                
                <mat-radio-group aria-label="Select an option" name="type" [(ngModel)]="type">
                    <div class="quote_radio row mb-3">
                        <div class="col-md-4">
                            <mat-radio-button [value]="1">Transaction Date</mat-radio-button>
                        </div>
                        <div class="col-md-4">
                            <mat-radio-button [value]="2">Written</mat-radio-button>
                        </div>
                        <div class="col-md-4">
                            <mat-radio-button [value]="3">Paid Date</mat-radio-button>
                        </div>
                    </div>
                </mat-radio-group>
            </div>
            <div class="form-group">
                <button class="btn btn-success btn-gray" (click)="download()">Submit</button>
            </div>
        </div>
    </div>
</div>