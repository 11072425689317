import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedService } from '../services/shared.service';

 
@Injectable({
  providedIn: 'root'
})
export class AffinityPartnerService {

  constructor(private sharedService: SharedService,private httpClient: HttpClient) { }

  // GET LIST OF PARENT COMPANIES
  getParentCompanies(company_id) {
    return this.sharedService.getAPI('companies/parent/' + company_id);
  }

  // SAVE COMPANY DETAILS
  saveAffinityPartners(data) {
    return this.sharedService.postFormData('saveaffinityurl',data);
  }

  // GET LIST OF ALL AFFINITY PARTNER
  getAllAffinityPartners() {
    return this.sharedService.getAPI('getaffinityurl');
  }

  // GET AFFINITY PARTNER BY ID 
  getAffinityPartnerByID(id) {
    return this.sharedService.getAPI('getaffinityurl/' + id);
    
  }

  // GET PRODUCTS
  getProducts() {
    return this.sharedService.getAPI('products');
  }
  // SAVE PRODUCTS
  saveProducts(data) {
    return this.sharedService.postAPI('company/products',data);
  }


}
